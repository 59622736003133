import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import borrar from "../../../img/borrar.svg";
import editar from "../../../img/editar.svg";
import { Link, useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"

export const ListUser = ({busqueda, listado}) => {
  const { jwt, notification, logout} = useUser();

  const [usuarios, setUsuarios] = useState(listado);
  const [borrado, setBorrado] = useState(null);

  const navigate = useNavigate();

  const getUsuariosFiltered = () => {
    setUsuarios(()=>{
        if(busqueda !== "") {
            return listado.filter(
                usuario => {
                    return (
                        usuario.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
                        usuario.email.toLowerCase().includes(busqueda.toLowerCase()) ||
                        usuario.telefono.toLowerCase().includes(busqueda.toLowerCase()) //||
                        //usuario.role.toLowerCase().includes(busqueda.toLowerCase())
                    );
                }
            )
        }else{
            return listado;
        }
    });
  };

  const eliminarUsuario = async (usuario, posicion) => {
      if (window.confirm("Vas a borrar al usuario. ¿Quieres continuar?")){
            const request = await fetch(Global.url + "usuarios/delete/" + usuario, {
              method: "DELETE",
              headers: { Authorization: "Bearer " + jwt },
            });

            const datos = await request.json();

            if (datos.status === 201) {
                listado.splice(posicion, 1);
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Usuario borrado",
                  message: "Usuario borrado correctamente.",
                  type: "success",
                })
                setBorrado(usuario);
            } else if(datos.status === 401){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                type: "danger",
              });
              logout()
            }
            else if(datos.status === 400){
              datos.then((value) => {
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: value.errors[0].message,
                  type: "danger",
                })
              });
            }
            else if(datos.status === 500){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Ha ocurrido un error",
                type: "danger",
              })
            }
      }
  };

    useEffect(() => {
        getUsuariosFiltered();
    }, [borrado, busqueda, listado]);

  const [open, setOpen] = useState("true");

  return (
    <div className="contenedorListadoVeterinario">
      {borrado === "error" ? (Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error al eliminar el usuario, por favor vuelva a intentarlo..",
          type: "danger",
        })) : (
        ""
      )}

      {usuarios &&
        usuarios.map((usuario, index) => {
          return (
            <Card
              className="listaVeterinarios"
              sx={{ minWidth: 300 }}
              key={index}
            >
              <CardContent>
              <Link to={"/usuario/"+usuario.id} ><Typography variant="h6" color="text.principal" gutterBottom>
                  Nombre: {usuario.nombre}
                </Typography></Link>
                <Typography sx={{ fontSize: 14 }} color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'>
                  Cargo: {usuario.role === "1" ? "Responsable" : "Veterinario"}
                </Typography><br/>
                <Typography sx={{ fontSize: 14 }} color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'>
                  Teléfono: {usuario.telefono}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                  E-mail: {usuario.email}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <Link to={"/usuario/"+usuario.id} ><img src={editar} width="20" alt="editar" /></Link>
                </Button>
                <Button
                  size="small"
                  onClick={(e) => eliminarUsuario(usuario.id, index)}
                  value={usuario.id}
                >
                  <img
                    src={borrar}
                    width="20"
                    alt="borrar"
                    value={usuario.id}
                  />
                </Button>
              </CardActions>
            </Card>
            
          );
        })}
    </div>
  );
};

